import React from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Container } from 'reactstrap';
import logoAlt from '../assets/images/logo-alt.svg';
import logoAltcoinomy from '../assets/images/altcooinomy.png';

function Footer(props) {
  return (
    <Container tag="footer" fluid className="mt-4 mt-md-5">
      <Row>
        <Col xs="12">
          <Container className="section-content-wrapper">
            <Row>
              <Col xs="12" md="3" className="section-content">
                <img src={ logoAlt } className="footer-logo" alt={props.CommonStore.appName} />
              </Col>
              <Col xs="6" md="3" className="section-content">
                1 Place des Florentins<br />
                1204 Geneva<br />
                Switzerland
              </Col>
              <Col xs="6" md="3" className="section-content">
                <ul className="menu">
                  <li><a href="/">What we do</a></li>
                  <li><a href="/">Our services</a></li>
                  <li><a href="/">Live tokenized offering</a></li>
                </ul>
              </Col>
              <Col xs="12" md="3" className="text-md-right section-content">
                <ul className="menu">
                  <li><a href="/">Twitter</a></li>
                  <li><a href="/">Facebook</a></li>
                </ul>
                <div className="footer-brand-text text-md-right">
                  <span>A brand by</span>
                  <img src={ logoAltcoinomy } className="footer-brand-logo" alt="Altcoinomy" />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default inject('CommonStore')(observer(Footer));
