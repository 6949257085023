import React, { useEffect } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import ListWrapper from '../../components/Subscription/ListWrapper';

function SubscriptionList(props) {
  const { url } = useRouteMatch();
  const { SubscriptionStore } = props;
  const { subscriptions, loading } = SubscriptionStore;

  useEffect(() => {
    SubscriptionStore.loadSubscriptions();
  }, [SubscriptionStore]);

  return (
    <Row>
      <Col>
        <Row className="justify-content-md-between align-items-md-center mb-3">
          <Col xs="12" md={{size: 'auto'}}>
            <h1>Subscription <small>list</small></h1>
          </Col>
          <Col xs="12" md={{size: 'auto'}}>
            <Link to={`${url}/new`} className="btn btn-primary w-100">New</Link>
          </Col>
        </Row>

        <ListWrapper
          subscriptions={subscriptions}
          loading={loading}
        />
      </Col>
    </Row>
  );
}

export default inject('SubscriptionStore')(observer(SubscriptionList));
